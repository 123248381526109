<script lang="ts">
import {defineComponent} from 'vue'
import PlayGamePart from "@/components/PlayGamePart.vue";
import EnvironmentGame from "@/components/EnvironmentGame.vue";
import OurProject from "@/components/OurProject.vue";
import Monkeys from "@/components/Monkeys.vue";
import DiscoverNFTS from "@/components/DiscoverNFTS.vue";
import RoadMap from "@/components/RoadMap.vue";
import MakeMoney from "@/components/MakeMoney.vue";
import Tokonomics from "@/components/Tokonomics.vue";
import PurchaseDGT from "@/components/PurchaseDGT.vue";
import WhitePaper from "@/components/WhitePaper.vue";
import Footer from "@/components/Footer.vue";
import EnvironmentGameMobile from "@/components/EnvironmentGameMobile.vue";
import {mapGetters} from "vuex";
import NewRoadMap from "@/components/NewRoadMap.vue";
import {gsap} from 'gsap';
import {CSSPlugin} from 'gsap/CSSPlugin'
import {MotionPathPlugin} from 'gsap/MotionPathPlugin'
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {TextPlugin} from "gsap/TextPlugin";
import DiscoverNFTSNew from "@/components/DiscoverNFTSNew.vue";
import {elements} from "chart.js";
import Header from "@/components/Header.vue";

gsap.registerPlugin(CSSPlugin, ScrollTrigger, MotionPathPlugin, TextPlugin);

export default defineComponent({
  name: "Home",
  components: {
    Header,
    DiscoverNFTSNew,
    NewRoadMap,
    EnvironmentGameMobile,
    Footer,
    WhitePaper,
    PurchaseDGT,
    Tokonomics, MakeMoney, RoadMap, DiscoverNFTS, Monkeys, OurProject, EnvironmentGame, PlayGamePart
  },
  data()
  {
    return {
      // platform : 'desktop',
      initChart: 1,
    }
  },
  mounted()
  {
    this.initGsap()

  },
  methods: {
    initGsap()
    {
      if (this.platform === 'desktop')
      {
        const playGamePart = gsap.timeline({
          scrollTrigger: {
            trigger: '#playGameContainer',
            start: 'top center',
            end: 'bottom top',
            // markers : true
          }
        });
        playGamePart.fromTo('#playGameContainer .img-back', {x: '100%'}, {x: 0, duration: 1, delay: .1}, 'p1-label')
        playGamePart.fromTo('#playGameContainer .left-img', {x: '150%', opacity: 0, xPercent: -50}, {
          y: 0,
          opacity: 1,
          x: 0,
          duration: 1,
          xPercent: -50,
          delay: 1
        }, 'p1-label');
        playGamePart.fromTo('#playGameContainer .p1', {opacity: 0}, {
          opacity: 1,
          animationName: 'flipInX',
          duration: 1
        }, 'p2-label')
        playGamePart.fromTo('#playGameContainer .p2', {opacity: 0}, {
          opacity: 1,
          animationName: 'flipInX',
          duration: 1,
          delay: .3
        }, 'p2-label')
        playGamePart.fromTo('#playGameContainer .p3', {opacity: 0}, {
          opacity: 1,
          animationName: 'flipInX',
          duration: 1,
          delay: .6
        }, 'p2-label')
        playGamePart.fromTo('#playGameContainer .p4', {opacity: 0}, {
          opacity: 1,
          animationName: 'flipInX',
          duration: 1,
          delay: .9
        }, 'p2-label')

        const EnvironmentGame = gsap.timeline({
          scrollTrigger: {
            trigger: '#EnvironmentGame',
            start: 'center bottom',
            end: 'bottom bottom',
            // markers : true
          }
        });
        EnvironmentGame.fromTo('#swiper_slider', {scale: 1, opacity: 0}, {scale: 1, opacity: 1, duration: 1})
        EnvironmentGame.fromTo('#EnvironmentGame .swiper-thumb2', {scale: 1, opacity: 0}, {
          scale: 1,
          opacity: 1,
          duration: 1
        });

        const discover_page = gsap.timeline({
          scrollTrigger: {
            trigger: '#discover_page',
            start: '50% 60%',
            end: 'bottom bottom',
            markers: false
          }
        });
        discover_page.fromTo('#discover_page .nftLeft1', {
          opacity: 0,
          x: '-100%',
          xPercent: -50,
          yPercent: -50
        }, {opacity: 1, scale: 1, x: '0%', duration: 1, xPercent: -50, yPercent: -50}, 'nft1');
        discover_page.fromTo('#discover_page .nftLeft2', {
          opacity: 0,
          x: '-100%',
          xPercent: -50,
          yPercent: -50
        }, {opacity: 1, scale: 1, x: '0%', duration: 1, delay: .5, xPercent: -50, yPercent: -50}, 'nft1');
        discover_page.fromTo('#discover_page .nftRight1', {
          opacity: 0,
          x: '100%',
          xPercent: -50,
          yPercent: -50
        }, {opacity: 1, scale: 1, x: '0%', duration: 1, xPercent: -50, yPercent: -50}, 'nft1');
        discover_page.fromTo('#discover_page .nftRight2', {
          opacity: 0,
          x: '100%',
          xPercent: -50,
          yPercent: -50
        }, {opacity: 1, scale: 1, x: '0%', duration: 1, delay: .5, xPercent: -50, yPercent: -50}, 'nft1');
        discover_page.fromTo('#discover_page .nftRight3', {opacity: 0, x: '100%'}, {
          opacity: 1,
          scale: 1,
          x: '0%',
          duration: 1,
          delay: .7,
          rotateY: '180deg',
          xPercent: 50,
          yPercent: -50
        }, 'nft1');
        discover_page.fromTo('#discover_page .container', {opacity: 0, scale: 0}, {
          opacity: 1,
          scale: 1,
          delay: 1.4,
        }, 'nft1');

        const makeMoneyPage = gsap.timeline({
          scrollTrigger: {
            trigger: '#makeMoneyPage',
            start: '30% bottom',
            end: 'bottom bottom',
            // markers : true
          }
        });
        makeMoneyPage.fromTo('#makeMoneyPage .level1', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label');
        makeMoneyPage.fromTo('#makeMoneyPage .content1', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label');
        makeMoneyPage.fromTo('#makeMoneyPage .image1', {opacity: 0, x: '100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label');
        makeMoneyPage.fromTo('#makeMoneyPage .level2', {opacity: 0, x: '100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label2');
        makeMoneyPage.fromTo('#makeMoneyPage .content2', {opacity: 0, x: '100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label2');
        makeMoneyPage.fromTo('#makeMoneyPage .screen2', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label2');
        makeMoneyPage.fromTo('#makeMoneyPage .level3', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label3');
        makeMoneyPage.fromTo('#makeMoneyPage .content3', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label3');
        makeMoneyPage.fromTo('#makeMoneyPage .screen3', {opacity: 0, x: '100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label3');

        const tokonomicsPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#tokonomicsPart',
            start: '60% 70%',
            end: 'bottom bottom',
            // markers : true,
            onEnter: () =>
            {
              this.initChart++;

            }
          }
        });
        tokonomicsPart.fromTo('#chart1', {opacity: 0, x: '-100%'}, {opacity: 1, x: '0%', duration: 1}, 'chart-label')
        tokonomicsPart.fromTo('#chart2', {opacity: 0, x: '100%'}, {opacity: 1, x: '0%', duration: 1}, 'chart-label');

        const PurchaseDGTPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#PurchaseDGTPart',
            start: '20% 70%',
            end: 'bottom bottom',
            // markers : true
          }
        });
        PurchaseDGTPart.fromTo('#PurchaseDGTPart .coin-img', {scale: 0, rotateY: 0}, {
          scale: 1,
          rotateY: 720,
          duration: 1
        }, 'pu1');
        PurchaseDGTPart.fromTo('#PurchaseDGTPart .connect-content', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1
        }, 'pu1')

        const monkey_part = gsap.timeline({
          scrollTrigger: {
            trigger: '#monkey_part',
            start: '20% 70%',
            end: 'bottom bottom',
            markers: false
          }
        });
        monkey_part.fromTo('#monkey_part .img-div', {opacity: 0, y: '-100%'}, {
          opacity: 1,
          y: '0',
          duration: 1
        }, 'monkey1')
        monkey_part.fromTo('#monkey_part .content-div', {opacity: 0, y: '100%'}, {
          opacity: 1,
          y: '0',
          duration: 1
        }, 'monkey1')

        const ourProjectPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#ourProjectPart',
            start: '20% 70%',
            end: 'bottom bottom',
            // markers : true
          }
        });
        ourProjectPart.fromTo('#ourProjectPart .left-part', {opacity: 0}, {
          opacity: 1,
          animationName: 'fadeInLeft'
        }, 'our1');
        ourProjectPart.fromTo('#ourProjectPart .right-part', {opacity: 0}, {
          opacity: 1,
          animationName: 'fadeInRight'
        }, 'our1');
        ourProjectPart.fromTo('#ourProjectPart .our-project-slider', {opacity: 0}, {
          opacity: 1,
          animationName: 'fadeIn'
        });

        const roadMpaPart = gsap.timeline({
          scrollTrigger: {
            trigger: '.road-img',
            start: '0% 70%',
            end: 'bottom bottom',
            // markers : true
          }
        });
        roadMpaPart.fromTo(' .road-img', {opacity: 0}, {opacity: 1, duration: 1});

        const whitePaperPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#whitePaperPart',
            start: '0% 70%',
            end: 'bottom bottom',
            // markers : true
          }
        });
        whitePaperPart.fromTo('#whitePaperPart', {opacity: 0}, {opacity: 1, duration: 1});
        whitePaperPart.fromTo('#whitePaperPart .container', {opacity: 0}, {
          opacity: 1,
          animationName: 'fadeInLeft',
          duration: 1
        }, 'paper1');
        whitePaperPart.fromTo('#whitePaperPart .pdf-img', {opacity: 0, yPercent: -50, xPercent: -50}, {
          opacity: 1,
          animationName: 'fadeInRight',
          duration: 1,
          yPercent: -50,
          xPercent: -50
        }, 'paper1');

        const footerPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#footerPart',
            start: '0% 70%',
            end: 'bottom bottom',
            // markers : true,

          }
        });

        footerPart.fromTo('#footerPart .title1', {opacity: 0}, {opacity: 1, animationName: 'fadeInLeft', duration: 1})
        footerPart.fromTo('#footerPart hr', {width: '0%'}, {width: '100%', duration: 1}, 'f1')
        footerPart.fromTo('#footerPart .icon1', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpLeft',
          duration: 1
        }, 'f1')
        footerPart.fromTo('#footerPart .icon2', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpLeft',
          duration: 1,
          delay: .3
        }, 'f1')
        footerPart.fromTo('#footerPart .icon3', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpLeft',
          duration: 1,
          delay: .6
        }, 'f1')
        footerPart.fromTo('#footerPart .icon4', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpLeft',
          duration: 1,
          delay: .9
        }, 'f1')
        footerPart.fromTo('#footerPart .menu1', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpRight',
          duration: 1,
          delay: .3
        }, 'f1')
        footerPart.fromTo('#footerPart .menu2', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpRight',
          duration: 1,
          delay: .6
        }, 'f1')
        footerPart.fromTo('#footerPart .menu3', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpRight',
          duration: 1,
          delay: .9
        }, 'f1')

      }
      else
      {
        const playGamePart = gsap.timeline({
          scrollTrigger: {
            trigger: '#playGameContainer',
            start: 'top center',
            end: 'bottom top',
            // markers : true
          }
        });
        playGamePart.fromTo('#playGameContainer .img-back', {x: '100%'}, {x: 0, duration: 1, delay: .1}, 'p1-label')
        playGamePart.fromTo('#playGameContainer .left-img', {x: '150%', opacity: 0, xPercent: -50}, {
          y: 0,
          opacity: 1,
          x: 0,
          duration: 1,
          xPercent: -50,
          delay: 1
        }, 'p1-label');
        playGamePart.fromTo('#playGameContainer .p1', {opacity: 0}, {
          opacity: 1,
          animationName: 'flipInX',
          duration: 1
        }, 'p2-label')
        playGamePart.fromTo('#playGameContainer .p2', {opacity: 0}, {
          opacity: 1,
          animationName: 'flipInX',
          duration: 1,
          delay: .3
        }, 'p2-label')
        playGamePart.fromTo('#playGameContainer .p3', {opacity: 0}, {
          opacity: 1,
          animationName: 'flipInX',
          duration: 1,
          delay: .6
        }, 'p2-label')
        playGamePart.fromTo('#playGameContainer .p4', {opacity: 0}, {
          opacity: 1,
          animationName: 'flipInX',
          duration: 1,
          delay: .9
        }, 'p2-label')

        const EnvironmentGame = gsap.timeline({
          scrollTrigger: {
            trigger: '#EnvironmentGame',
            start: 'center bottom',
            end: 'bottom bottom',
            // markers : true
          }
        });
        EnvironmentGame.fromTo('#EnvironmentGame', {scale: 1, opacity: 0}, {scale: 1, opacity: 1, duration: 1});

        const ourProjectPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#ourProjectPart',
            start: '20% 70%',
            end: 'bottom bottom',
            // markers : true
          }
        });
        ourProjectPart.fromTo('#ourProjectPart .left-part', {opacity: 0}, {
          opacity: 1,
          animationName: 'fadeInLeft'
        }, 'our1');
        ourProjectPart.fromTo('#ourProjectPart .right-part', {opacity: 0}, {
          opacity: 1,
          animationName: 'fadeInRight'
        }, 'our1');
        ourProjectPart.fromTo('#ourProjectPart .our-project-slider', {opacity: 0}, {
          opacity: 1,
          animationName: 'fadeIn'
        });

        const monkey_part = gsap.timeline({
          scrollTrigger: {
            trigger: '#monkey_part',
            start: '20% 70%',
            end: 'bottom bottom',
            markers: false
          }
        });
        monkey_part.fromTo('#monkey_part .img-div', {opacity: 0, y: '100%'}, {
          opacity: 1,
          y: '0',
          duration: 1,
          delay: .5
        }, 'monkey1')
        monkey_part.fromTo('#monkey_part .content-div', {opacity: 0, y: '100%'}, {
          opacity: 1,
          y: '0',
          duration: 1
        }, 'monkey1');

        const discover_page = gsap.timeline({
          scrollTrigger: {
            trigger: '#discover_page',
            start: '50% 60%',
            end: 'bottom bottom',
            markers: false
          }
        });
        discover_page.fromTo('#discover_page .nftLeft1', {
          opacity: 0,
          x: '-100%',
          xPercent: -50,
          yPercent: -50
        }, {opacity: 1, scale: 1, x: '0%', duration: 1, xPercent: -50, yPercent: -50}, 'nft1');
        discover_page.fromTo('#discover_page .nftLeft2', {
          opacity: 0,
          x: '-100%',
          xPercent: -50,
          yPercent: -50
        }, {opacity: 1, scale: 1, x: '0%', duration: 1, delay: .5, xPercent: -50, yPercent: -50}, 'nft1');
        discover_page.fromTo('#discover_page .nftRight1', {
          opacity: 0,
          x: '100%',
          xPercent: -50,
          yPercent: -50
        }, {opacity: 1, scale: 1, x: '0%', duration: 1, xPercent: -50, yPercent: -50}, 'nft1');
        discover_page.fromTo('#discover_page .nftRight2', {
          opacity: 0,
          x: '100%',
          xPercent: -50,
          yPercent: -50
        }, {opacity: 1, scale: 1, x: '0%', duration: 1, delay: .5, xPercent: -50, yPercent: -50}, 'nft1');
        discover_page.fromTo('#discover_page .nftRight3', {opacity: 0, x: '100%'}, {
          opacity: 1,
          scale: 1,
          x: '0%',
          duration: 1,
          delay: .7,
          rotateY: '180deg',
          xPercent: 50,
          yPercent: -50
        }, 'nft1');
        discover_page.fromTo('#discover_page .container', {opacity: 0, scale: 0}, {
          opacity: 1,
          scale: 1,
          delay: 1.4,
        }, 'nft1');

        const makeMoneyPage = gsap.timeline({
          scrollTrigger: {
            trigger: '#makeMoneyPage',
            start: '30% bottom',
            end: 'bottom bottom',
            // markers : true
          }
        });
        makeMoneyPage.fromTo('#makeMoneyPage .level1', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label');
        makeMoneyPage.fromTo('#makeMoneyPage .content1', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label');
        makeMoneyPage.fromTo('#makeMoneyPage .image1', {opacity: 0, x: '100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label');
        makeMoneyPage.fromTo('#makeMoneyPage .level2', {opacity: 0, x: '100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label2');
        makeMoneyPage.fromTo('#makeMoneyPage .content2', {opacity: 0, x: '100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label2');
        makeMoneyPage.fromTo('#makeMoneyPage .screen2', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label2');
        makeMoneyPage.fromTo('#makeMoneyPage .level3', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label3');
        makeMoneyPage.fromTo('#makeMoneyPage .content3', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label3');
        makeMoneyPage.fromTo('#makeMoneyPage .screen3', {opacity: 0, x: '100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1.5
        }, 'money-label3');

        const tokonomicsPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#tokonomicsPart',
            start: '60% 70%',
            end: 'bottom bottom',
            // markers : true,
            onEnter: () =>
            {
              this.initChart++;

            }
          }
        });
        tokonomicsPart.fromTo('#chart1', {opacity: 0, x: '-100%'}, {opacity: 1, x: '0%', duration: 1}, 'chart-label')
        tokonomicsPart.fromTo('#chart2', {opacity: 0, x: '100%'}, {opacity: 1, x: '0%', duration: 1}, 'chart-label');

        const PurchaseDGTPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#PurchaseDGTPart',
            start: '20% 70%',
            end: 'bottom bottom',
            // markers : true
          }
        });
        PurchaseDGTPart.fromTo('#PurchaseDGTPart .coin-img', {scale: 0, rotateY: 0}, {
          scale: 1,
          rotateY: 720,
          duration: 1
        }, 'pu1');
        PurchaseDGTPart.fromTo('#PurchaseDGTPart .connect-content', {opacity: 0, x: '-100%'}, {
          opacity: 1,
          x: '0%',
          duration: 1
        }, 'pu1')

        const whitePaperPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#whitePaperPart',
            start: '0% 70%',
            end: 'bottom bottom',
            // markers : true
          }
        });
        whitePaperPart.fromTo('#whitePaperPart', {opacity: 0}, {opacity: 1, duration: 1});
        whitePaperPart.fromTo('#whitePaperPart .container', {opacity: 0}, {
          opacity: 1,
          animationName: 'fadeInLeft',
          duration: 1
        }, 'paper1');
        whitePaperPart.fromTo('#whitePaperPart .pdf-img', {opacity: 0, yPercent: -50, xPercent: -50}, {
          opacity: 1,
          animationName: 'fadeInRight',
          duration: 1,
          yPercent: -50,
          xPercent: -50
        }, 'paper1');

        const footerPart = gsap.timeline({
          scrollTrigger: {
            trigger: '#footerPart',
            start: '0% 70%',
            end: 'bottom bottom',
            // markers : true,
          }
        });

        footerPart.fromTo('#footerPart .title1', {opacity: 0}, {opacity: 1, animationName: 'fadeInLeft', duration: 1})
        footerPart.fromTo('#footerPart hr', {width: '0%'}, {width: '100%', duration: 1}, 'f1')
        footerPart.fromTo('#footerPart .icon1', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpLeft',
          duration: 1
        }, 'f1')
        footerPart.fromTo('#footerPart .icon2', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpLeft',
          duration: 1,
          delay: .3
        }, 'f1')
        footerPart.fromTo('#footerPart .icon3', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpLeft',
          duration: 1,
          delay: .6
        }, 'f1')
        footerPart.fromTo('#footerPart .icon4', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpLeft',
          duration: 1,
          delay: .9
        }, 'f1')
        footerPart.fromTo('#footerPart .menu1', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpRight',
          duration: 1,
          delay: .3
        }, 'f1')
        footerPart.fromTo('#footerPart .menu2', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpRight',
          duration: 1,
          delay: .6
        }, 'f1')
        footerPart.fromTo('#footerPart .menu3', {opacity: 0}, {
          opacity: 1,
          animationName: 'rotateInUpRight',
          duration: 1,
          delay: .9
        }, 'f1')

        const rocket_img = gsap.timeline({
          opacity: 0,
          scrollTrigger: {
            trigger: '#rocket_img',
            start: '0% 30%',
            end: '+=750',
            // markers : true,
            scrub: 3,
            pinSpacing: true,
            pin: true,
            once: true,
          }
        });
        // rocket_img.to('#rocket_img',{opacity})
        // rocket_img.fromTo('#rocket_img',{opacity : 0},{opacity:1})
        const roadMpaPart1 = gsap.timeline({
          scrollTrigger: {
            trigger: '#roadMpaPart .planet1',
            start: 'top 70%',
            end: 'bottom bottom',
            // markers : true,
          }
        });
        roadMpaPart1.fromTo('#roadMpaPart .planet1', {opacity: 0, xPercent: -50, yPercent: 10}, {
          opacity: 1,
          xPercent: -50,
          yPercent: 10,
          animationName: 'fadeInDownBig',
          animationDuration: 1,
          delay: 0,
          duration: 1
        })
        const roadMpaPart2 = gsap.timeline({
          scrollTrigger: {
            trigger: '#roadMpaPart .planet2',
            start: 'top 70%',
            end: 'bottom bottom',
            // markers : true,
          }
        });
        roadMpaPart2.fromTo('#roadMpaPart .planet2', {opacity: 0, xPercent: -50, yPercent: 10}, {
          opacity: 1,
          xPercent: -50,
          yPercent: 10,
          animationName: 'fadeInDownBig',
          animationDuration: 1,
          delay: 0,
          duration: 1
        })
        const roadMpaPart3 = gsap.timeline({
          scrollTrigger: {
            trigger: '#roadMpaPart .planet3',
            start: 'top 70%',
            end: 'bottom bottom',
            // markers : true,
          }
        });
        roadMpaPart3.fromTo('#roadMpaPart .planet3', {opacity: 0, xPercent: -50, yPercent: 10}, {
          opacity: 1,
          xPercent: -50,
          yPercent: 10,
          animationName: 'fadeInDownBig',
          animationDuration: 1,
          delay: 0,
          duration: 1
        })
        const roadMpaPart4 = gsap.timeline({
          scrollTrigger: {
            trigger: '#roadMpaPart .planet4',
            start: 'top 70%',
            end: 'bottom bottom',
            // markers : true,
          }
        });
        roadMpaPart4.fromTo('#roadMpaPart .planet4', {opacity: 0, xPercent: -50, yPercent: 10}, {
          opacity: 1,
          xPercent: -50,
          yPercent: 10,
          animationName: 'fadeInDownBig',
          animationDuration: 1,
          delay: 0,
          duration: 1
        })

      }
    }
  },
  computed: {
    ...mapGetters('web3', {
      isWeb3Available: 'isWeb3Available',
      isWeb3Injected: 'isWeb3Injected',
      currentAccount: 'getCurrentAccount',
      currentNetworkId: 'getCurrentNetworkId',
      isConnectedToRightNetwork: 'isConnectedToRightNetwork',
      platform: "platform"
    })
  }
})
</script>

<template>

  <Header/>

  <main>
    <PlayGamePart id="top"></PlayGamePart>
    <EnvironmentGame id="gameIntro" v-if="platform === 'desktop'"></EnvironmentGame>
    <EnvironmentGameMobile v-if="platform === 'mobile'"></EnvironmentGameMobile>
    <OurProject></OurProject>
    <Monkeys id="nft"></Monkeys>
    <!--    <DiscoverNFTS></DiscoverNFTS>-->
    <DiscoverNFTSNew></DiscoverNFTSNew>
    <RoadMap id="roadMap"></RoadMap>
    <!--    <NewRoadMap></NewRoadMap>-->
    <MakeMoney id="makeMoney"></MakeMoney>
    <tokonomics id="tokonomics" :init-chart="initChart"></tokonomics>
    <PurchaseDGT id="buyDgt"></PurchaseDGT>
    <WhitePaper id="whitepaper"></WhitePaper>
    <Footer></Footer>
  </main>
</template>

<style scoped lang="css">
main {
overflow-x: hidden;
}

</style>
