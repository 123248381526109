import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from "@/views/Home.vue";
import FrequentlyPage from "@/views/FrequentlyPage.vue";
import Admin from "@/views/Admin.vue";
import Referrer from "@/views/Referrer.vue";
import BuyChests from "@/views/BuyChests.vue";
import Dashboard from "@/views/Dashboard.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta : {title : 'DecentraGuys'}
  },
  {
    path: '/FAQ',
    name: 'FrequentlyPage',
    component: FrequentlyPage
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/ref/:address',
    name: 'Referrer',
    component: Referrer
  },
  {
    path: '/BuyChests',
    name: 'BuyChests',
    component: BuyChests
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
