import axios from "axios";
import store from '../store/index';
import {ApiHelper} from "@/api/api-helper";

const jwtInterceptor = axios.create({});


jwtInterceptor.interceptors.request.use((config) =>
{
  const authData = store.getters["auth/getAuthData"];
  const currentLocale = store.getters["app/getCurrentLocale"];
  if (authData && authData.token)
  {
    config.headers.common["Authorization"] = `bearer ${authData.token}`;
    config.headers.common["CurrentLocale"] = currentLocale;
  }

  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) =>
  {
    return response;
  },
  async (error) =>
  {
    if (error && error.response && error.response.status === 401)
    {
      const authData = store.getters["auth/getAuthData"];
      const payload = {
        access_token: authData?authData.token:'',
        refresh_token: authData?authData.refreshToken:'',
      };

      const url = ApiHelper.getApiUrl() + 'user/refreshToken'
      var response = await axios.post(
        url,
        payload
      );
      const authResultData = {
        access_token: response.data.token.token,
        refresh_token: response.data.token.refreshToken
      }
      await store.dispatch("auth/saveTokensToStorage", authResultData);
      error.config.headers[
        "Authorization"
        ] = `bearer ${response.data.access_token}`;
      return axios(error.config);
    }
    else
    {
      return Promise.reject(error);
    }
  }
);

export default jwtInterceptor;
