<script lang="ts">
import {defineComponent} from 'vue'
import SearchBox from "@/components/SearchBox.vue";

export default defineComponent({
  name: "Dashboard",
  components: {SearchBox},
  data(){
    return {
      tokenCount: 250,
      yourToken: 250,
      wonTimes: 12,
      totalTimes: 16
    }
  },
  methods: {
    handlingActiveItem(e: any){
      if(e.target){
        const allItems: any = this.$refs.wrapperMenu
        if(allItems){
          const menuItems = allItems.querySelectorAll('.menu-item-dashboard')
          if(menuItems){
            menuItems.forEach(item => {
              if(item)
                item.classList.remove('active')
            })
            e.target.classList.add('active');
          }
        }
      }
    }
  }
})
</script>

<template>

  <div style="background: #16091F" class="d-flex flex-column">
    <div class="d-flex col-12 p-5 align-items-center">
      <div class="d-flex col-lg-3">
        <p class="gradient-text">
          Decentraguys
        </p>
      </div>
      <div class="d-flex flex-column col-9 px-3 position-relative">
        <div class="d-flex align-items-center col-12">
          <p class="big-text bold-text mx-3 mb-0">
            Dashboard
          </p>
          <SearchBox class="w-100" />
        </div>
      </div>
    </div>

    <div class="d-flex col-12">
      <div class=" col-12 col-lg-3 pt-3">
        <div ref="wrapperMenu" class="d-flex flex-column wrapper-menu">
          <span @click="handlingActiveItem" class="menu-item-dashboard active">
            Dashboard
          </span>
          <span @click="handlingActiveItem" class="menu-item-dashboard">
            Dashboard
          </span>
          <span @click="handlingActiveItem" class="menu-item-dashboard">
            Dashboard
          </span>
          <span @click="handlingActiveItem" class="menu-item-dashboard">
            Dashboard
          </span>
          <span @click="handlingActiveItem" class="menu-item-dashboard">
            Dashboard
          </span>
          <span @click="handlingActiveItem" class="menu-item-dashboard">
            Dashboard
          </span>
          <span @click="handlingActiveItem" class="menu-item-dashboard">
            Dashboard
          </span>
          <span @click="handlingActiveItem" class="menu-item-dashboard">
            Dashboard
          </span>
        </div>
      </div>
      <div class="d-flex flex-column col-9 px-3 pt-2 position-relative">
        <div class="d-flex flex-wrap col-12">
          <div class="col-12 col-lg-4 p-2">
            <div class="info-box d-flex justify-content-between align-items-center">
              <div class="d-flex flex-column">
                <p class="text">income from selling:</p>
                <div class="d-flex align-items-center">
                  <img style="margin-right: 15px" src="@/assets/images/Dashboard/import.png" alt="">
                  <p class="mb-0"><span class="bold-text-big">{{ tokenCount }} Token</span></p>
                </div>
              </div>
              <img src="@/assets/images/Dashboard/chest.png" alt="">
            </div>
          </div>
          <div class="col-12 col-lg-4 p-2">
            <div class="info-box d-flex justify-content-between align-items-center">
              <div class="d-flex flex-column">
                <p class="text">Your Token :</p>
                <p class="mb-0"><span class="bold-text-big">{{ yourToken }} Token</span></p>
              </div>
              <img src="@/assets/images/Dashboard/token.png" alt="">
            </div>
          </div>
          <div class="col-12 col-lg-4 p-2">
            <div class="info-box d-flex justify-content-between flex-column">
              <div class="d-flex align-items-center justify-content-between">
                <p><span class="text">won:</span> <span style="margin-left: 5px" class="bold-text-big">{{ wonTimes }} Times</span></p>
                <img src="@/assets/images/Dashboard/cup.png" alt="">
              </div>
              <p class="mb-0"><span class="text">Total game:</span> <span style="margin-left: 5px" class="bold-text-big">{{ totalTimes }} Times</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<style scoped lang="css">

.info-box {
  background: #29103A;
  padding: 17px;
  height: 120px;
  border-radius: 10px;
}

.wrapper-menu {
  background: #29103A;
  height: 1000px;
  border-radius: 0 10px 10px 0;
  padding: 20px 2px 20px 0;
}

.bold-text-big {
  font-size: 24px;
  font-weight: 700;
}

.gradient-text {
  font-size: 45px;
  background: -webkit-linear-gradient(99.24deg, #9400FF -13.99%, #3AD9DA 77.28%, #54F5F6 126.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.bold-text {
  font-weight: bold;
}

.menu-item-dashboard {
  padding: 10px 10px 10px 40px ;
  background: transparent;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
  transition: 0.2s;
}

.menu-item-dashboard.active {
  background: linear-gradient(-90deg , rgba(84, 245, 246, 0.49), rgba(255, 255, 255, 0));
  border-right: 3px solid rgba(84, 245, 246, 1);
  transition: 0.2s;
}

</style>