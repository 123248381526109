<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "DiscoverNFTSNew",
  data()
  {
    return{
      platform : 'desktop'
    }
  },
  mounted() {
    const window_width = window.innerWidth;
    if (window_width < 650)
      this.platform = 'mobile'
  }
})
</script>

<template>
  <div class="page" id="discover_page">
    <img src="@/assets/images/nftsBack.webp" alt="" class="back-img">
    <img src="@/assets/images/nftLeft1.webp" alt="" class="nftLeft1">
    <img src="@/assets/images/nftLeft2.webp" alt="" class="nftLeft2">
    <img src="@/assets/images/nftRight1.webp" alt="" class="nftRight1">
    <img src="@/assets/images/nftRight2.webp" alt="" class="nftRight2">
    <img src="@/assets/images/nftRight3.webp" alt="" class="nftRight3">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 mx-auto">
          <div class="page-titles title2">Discover rare NFTS</div>
          <div class="page-contents page-content2">
            NFTs are unique digital assets that are stored on a blockchain, typically using Ethereum or other blockchain networks
            There are several types of NFT in Decentra guys: Characters, Monkeys, Open world Lands and more!
          </div>
          <button class="btn btn-gradient">download</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.page {
  position: relative;
  margin-top: -40%;
  margin-bottom: -20%;
}
.back-img
{
  width: 100%;
}
.nftLeft1 {
  position: absolute;
  left: 9.1%;
  bottom: 14%;
  width: 25%;
  transform: translateX(-50%) translateY(-50%);
}
.nftLeft2 {
  position: absolute;
  left: 23%;
  bottom: 15%;
  width: 20%;
  transform: translateX(-50%) translateY(-50%);
}
.nftRight1 {
  position: absolute;
  right: -20%;
  bottom: 16%;
  width: 27.5%;
  transform: translateX(-50%) translateY(-50%);
}
.nftRight2 {
  position: absolute;
  right: 7%;
  bottom: 23%;
  width: 15%;
  transform: translateX(-50%) translateY(-50%);
}
.nftRight3 {
  position: absolute;
  right: 29%;
  bottom: 19%;
  width: 13%;
  transform: rotateY(180deg) translateX(-50%) translateY(-50%);
}
.page .container {
  position: absolute;
  bottom: 43%;
  display: block;
  text-align: center;
  /* width: 100%; */
  transform: translateX(-50%);
  left: 50%;
}
.page-content2 {
  width: 70%;
  display: block;
  margin: 10px auto;
}
.title2
{
  font-size: 4vw;
}
.mobile .page {
  margin-top: 0%;
  margin-bottom: -10%;
}
.mobile .container {
  position: absolute;
  bottom: unset;
  top: 5%;
  width: 100%!important;
}
.mobile .page-content2 {
  width: 90%;
  display: block;
  margin: 10px auto;
}
</style>