<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent( {
  name: "EnvironmentGameMobile",
  data(){
    return{
      showItems : false,
    }
  }
})
</script>

<template>
  <div class="container" id="EnvironmentGame">
    <div class="page-titles page-title">Game Environment</div>
    <img src="@/assets/images/image-2.webp" alt="" class="top-img">
    <div :class="'items mt-3 '+(showItems?'show':'') ">
      <div :class="'cover-div '+(showItems?'hide':'')">
        <button @click="showItems = !showItems" class="btn btn-gradient mb-3 view-all-btn">view all</button>
      </div>
      <div class="item">
        <div class="img-div">
          <img src="@/assets/images/image-2.webp">
        </div>
        <div class="content">
          <div class="title">zobies</div>
          <div >Game Environment #1</div>
        </div>
      </div>
      <div class="item">
        <div class="img-div">
          <img src="@/assets/images/image-3.webp">
        </div>
        <div class="content">
          <div class="title">zobies</div>
          <div >Game Environment #2</div>
        </div>
      </div>
      <div class="item">
        <div class="img-div">
          <img src="@/assets/images/image-4.webp">
        </div>
        <div class="content">
          <div class="title">zobies</div>
          <div >Game Environment #3</div>
        </div>
      </div>
      <div class="item">
        <div class="img-div">
          <img src="@/assets/images/image-5.webp">
        </div>
        <div class="content">
          <div class="title">zobies</div>
          <div >Game Environment #4</div>
        </div>
      </div>
      <div class="item">
        <div class="img-div">
          <img src="@/assets/images/image-6.webp">
        </div>
        <div class="content">
          <div class="title">zobies</div>
          <div >Game Environment #5</div>
        </div>
      </div>
      <div class="item">
        <div class="img-div">
          <img src="@/assets/images/image-7.webp">
        </div>
        <div class="content">
          <div class="title">zobies</div>
          <div >Game Environment #6</div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="css">
.container
{
  margin-top: 8%;
}
.page-titles
{
  text-align: center;
}
.top-img
{
  width: 100%;
  object-fit: contain;
}
.items
{
  height: 450px;
  overflow: hidden;
  position: relative;
}
.items.show
{
  height:auto;
}
.items .item
{
  display: flex;
  margin-bottom: 10px;
  background: #1D0054;
  padding: 10px;
  border-radius: 8px;

}
.item .img-div
{
  display: flex;
  flex: 0 0 90px;
  aspect-ratio: 1;
  align-self: center;
}
.img-div img
{
  width: 100%;
  object-fit: cover;
  display: block;
}
.item .content
{
  flex: 0 0 auto;
  padding: 10px;
}
.cover-div
{
  position: absolute;
  height: 380px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(358.74deg, #160040 -1.14%, rgba(22, 0, 64, 0) 98.93%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.view-all-btn
{
  display: block;
  width: max-content;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
}
.cover-div.hide
{
  display: none;
}
</style>