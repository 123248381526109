import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_PlayGamePart = _resolveComponent("PlayGamePart")!
  const _component_EnvironmentGame = _resolveComponent("EnvironmentGame")!
  const _component_EnvironmentGameMobile = _resolveComponent("EnvironmentGameMobile")!
  const _component_OurProject = _resolveComponent("OurProject")!
  const _component_Monkeys = _resolveComponent("Monkeys")!
  const _component_DiscoverNFTSNew = _resolveComponent("DiscoverNFTSNew")!
  const _component_RoadMap = _resolveComponent("RoadMap")!
  const _component_MakeMoney = _resolveComponent("MakeMoney")!
  const _component_tokonomics = _resolveComponent("tokonomics")!
  const _component_PurchaseDGT = _resolveComponent("PurchaseDGT")!
  const _component_WhitePaper = _resolveComponent("WhitePaper")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("main", null, [
      _createVNode(_component_PlayGamePart, { id: "top" }),
      (_ctx.platform === 'desktop')
        ? (_openBlock(), _createBlock(_component_EnvironmentGame, {
            key: 0,
            id: "gameIntro"
          }))
        : _createCommentVNode("", true),
      (_ctx.platform === 'mobile')
        ? (_openBlock(), _createBlock(_component_EnvironmentGameMobile, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_component_OurProject),
      _createVNode(_component_Monkeys, { id: "nft" }),
      _createVNode(_component_DiscoverNFTSNew),
      _createVNode(_component_RoadMap, { id: "roadMap" }),
      _createVNode(_component_MakeMoney, { id: "makeMoney" }),
      _createVNode(_component_tokonomics, {
        id: "tokonomics",
        "init-chart": _ctx.initChart
      }, null, 8, ["init-chart"]),
      _createVNode(_component_PurchaseDGT, { id: "buyDgt" }),
      _createVNode(_component_WhitePaper, { id: "whitepaper" }),
      _createVNode(_component_Footer)
    ])
  ], 64))
}