<script lang="ts">
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import {mapActions} from "vuex";
import {Web3Helper} from "@/blockchain/web3-helper";

export default defineComponent({
  name: "App",
  components: {Header},
  data()
  {
    return {
      platform: 'desktop'
    }
  },
  mounted()
  {
    const window_width = window.innerWidth;
    if (window_width < 650)
      this.platform = 'mobile';
    this.setPlatform(this.platform);
    this.initiateWeb3State().then(async () =>
    {
      const windowWeb3 = Web3Helper.getWindowWeb3();
      if (windowWeb3)
      {
        windowWeb3.on('accountsChanged', async () =>
        {
          // @ts-ignore
          this.emitter.emit('web3Changed');

          console.log('accounts changed')
          await this.initiateWeb3State();
        });


        windowWeb3.on('chainChanged', async () =>
        {
          console.log('network changed');
          await this.initiateWeb3State();

          // @ts-ignore
          this.emitter.emit('web3Changed');
        });
      }
    });
  },
  methods: {
    ...mapActions('web3', {
      initiateWeb3State: 'initiateState',
      setPlatform : "setPlatform",
    })
  }
})
</script>

<template>
  <router-view :class="platform"/>
</template>

<style scoped lang="css">

</style>