<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "DiscoverNFTS",
  data()
  {
    return{
      platform : 'desktop'
    }
  },
  mounted() {
    const window_width = window.innerWidth;
    if (window_width < 650)
      this.platform = 'mobile'
  }
})
</script>

<template>
  <div class="page">
    <img v-if="platform === 'desktop'" class="back-img" src="@/assets/images/DiscoverNFTS.webp" alt="">
    <img v-if="platform === 'mobile'" class="back-img" src="@/assets/images/DiscoverNFTSMobile.webp" alt="">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 mx-auto">
          <div class="page-titles">Discover rare NFTS</div>
          <div class="page-contents">
            NFTs are unique digital assets that are stored on a blockchain, typically using Ethereum or other blockchain networks
            There are several types of NFT in Decentra guys: Characters, Monkeys, Open world Lands and more!
          </div>
          <button class="btn btn-gradient">download</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.page {
  position: relative;
  margin-top: -40%;
  margin-bottom: -20%;
}
.container {
  text-align: center;
  position: absolute;
  top: 51%;
  left: 0;
  right: 0;
  /* transform: translateY(1%); */
}
.btn-gradient
{
  margin : 5% 0;
}
.back-img
{
  width: 100%;
}
.mobile .page {
  margin-top: -20%;
  margin-bottom: 0;
}
.mobile .container {
  text-align: center;
  position: absolute;
  top: 10%;
}
</style>