import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './assets/styles/animate.min.css'
import './assets/styles/styles.css';
import store from "./store";
import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App).use(router).use(store);
app.config.globalProperties.emitter = emitter;
app.use(VueSweetalert2);
app.mount('#app')
