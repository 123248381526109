<script lang="ts">

import {defineComponent} from "vue";
import Cookies from "js-cookie";
import Web3 from "web3";

export default defineComponent({
  name: "Referrer",
  mounted()
  {
    const address = <string>this.$route.params.address;
    if (address && Web3.utils.isAddress(address))
    {
      Cookies.set('referrer', address)
    }
    window.location.replace('/')
  }
});
</script>

<template>

</template>

<style scoped lang="css">

</style>