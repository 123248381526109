<script lang="ts">
import {defineComponent} from 'vue'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Doughnut} from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)

export default defineComponent({
  name: "Tokonomics",
  components: {
    Doughnut
  },
  props: ['initChart'],
  data()
  {
    return {
      chartData: {
        labels: [
          'Developers: 10%',
          'Airdrop: 5%',
          'ITO: 30%',
          'Game Tokens: 55%'
        ],
        datasets: [
          {
            backgroundColor: ['#5C41A0', '#7AD6D6', '#FDBD39', '#627EEA'],
            data: [10, 5, 30, 55]
          }
        ]
      },
      itoChartData: {
        labels: [
          'Demo Release: 25%',
          'Connect Game To Web3: 20%',
          'Monkey Shop: 30%',
          'Public NFT Shop: 25%'
        ],
        datasets: [
          {
            backgroundColor: ['#5C41A0', '#7AD6D6', '#FDBD39', '#627EEA'],
            data: [25, 20, 30, 25]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        rotation: 120,
        plugins: {
          legend: {
            display: true,
            fullSize: true,
            position: 'bottom',
            maxHeight: 100,
            maxWidth: 100,
            labels: {
              color: 'white',
              font: 'Poppins',
              usePointStyle: true,
              pointStyle: 'circle',
              boxWidth: 100
            }
          }
        }
      }
    }
  }
})
</script>

<template>
  <div class="container" id="tokonomicsPart">
    <div class="page-titles mb-3">Tokonomics</div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="page-contents">
          It focuses on the economic principles, systems, and dynamics related to the use and value of tokens within a
          blockchain ecosystem
        </div>
      </div>
      <div class="col-12 col-md-6"></div>
      <div class="col-12 col-md-6 my-3" id="chart1">
        <div class="chart-title">Token Distribution</div>
        <div>
          <Doughnut :key="initChart" :data="chartData" :options="chartOptions"/>
        </div>
      </div>
      <div class="col-12 col-md-6 my-3" id="chart2">
        <div class="chart-title">ITO</div>
        <div class="">
          <Doughnut :key="initChart" :data="itoChartData" :options="chartOptions"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.container {
  margin-bottom: 10%;
}

.chart-title {
  font-size: 2vw;
}

.mobile .chart-title {
  font-size: 8vw;
}
</style>