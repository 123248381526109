<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "MakeMoney",
  data()
  {
    return{
      platform : 'desktop'
    }
  },
  mounted() {
    const window_width = window.innerWidth;
    if (window_width < 650)
      this.platform = 'mobile'
  }
})
</script>

<template>
  <div class="container" id="makeMoneyPage">
    <div class="page-titles">How make money</div>
    <div class="main-row">
      <div v-if="platform === 'desktop'" class="number">
        <div class="level level1">01</div>
      </div>
      <div class="content content1">
        <div class="title">Play 2 Earn <img class="title-mobile-icon" v-if="platform === 'mobile'" src="@/assets/images/people.svg" alt=""></div>
        <div class="page-contents">
          People and receive rewards and tokens by purchasing the chests in game based on their ratings.
          These tokens can be used for character development and in-game purchases and to exchange with other Digital coins.
        </div>
      </div>
      <div class="img-div image1">
        <div class="frame"></div>
      </div>
    </div>
    <div class="main-row main-row2">
      <div v-if="platform === 'desktop'" class="number">
        <div class="level level2">02</div>
      </div>
      <div class="content content2">
        <div class="title">decentraguys league <img class="title-mobile-icon" v-if="platform === 'mobile'" src="@/assets/images/game.svg" alt=""></div>
        <div class="page-contents">
          By competing in a seasonal Decentraguys league, players can get to the top of their league and achieve high ranks in the Tournamentmat,the top 10 players will receive valuable prizes.
          Also, the top 100 players will be added to the airdrops whitelist.
        </div>
      </div>
      <div class="img-div screen2">
        <div class="frame"></div>
      </div>
    </div>
    <div class="main-row">
      <div v-if="platform === 'desktop'" class="number">
        <div class="level level3">03</div>
      </div>
      <div class="content content3">
        <div class="title">In-game rewards <img class="title-mobile-icon" v-if="platform === 'mobile'" src="@/assets/images/usd-coin-(usdc).svg" alt=""></div>
        <div class="page-contents">
          During each game mode, whether it is the competition stages or open world quests, you will receive prizes, each of them having their own value.
          You can earn money by selling NFTs or obtained equipment or upgrading it in any parts of the project.
        </div>
      </div>
      <div class="img-div screen3">
        <div class="frame"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.level
{
  background: linear-gradient(180deg, #9400FF 0%, #3AD9DA 65%, #54F5F6 100%);
  font-family: GoodTiming;
  font-size: 4vw;
  -webkit-background-clip: text;
  color: #160040;
  -webkit-text-stroke: 8px transparent;
  }
.main-row
{
  display: flex;
  grid-gap: 6%;
  margin-bottom: 3%;
}
.mobile .main-row {
  flex-direction: column;
  grid-gap: 10px;
  margin-bottom: 25px;
}
.main-row .number
{
  flex: 0 0 max-content;
  align-self: center;
}
.main-row .content
{
  flex: 0 0 44%;
  align-self: center;
}
.main-row .img-div
{
  flex: 0 0 44%;
  aspect-ratio: 2/1;
  background-image: url("@/assets/images/img-2.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

}
.main-row .img-div.screen2{
  background-image: url("@/assets/images/screen2.webp");
}
.main-row .img-div.screen3{
  background-image: url("@/assets/images/new.jpg");
  background-size: contain;
}
.main-row .screen3 .frame {
  background: radial-gradient(50% 50% at 53% 56%, rgba(255, 255, 255, 0.00) 0%, #160040 100%)

}
.main-row .frame
{
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, #160040 100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.main-row2
{
  flex-direction: row-reverse;
}
.content .title
{
  font-weight: bold;
  margin-bottom: 5px;
}
.mobile .page-titles
{
  margin-bottom: 10px;
}
.mobile .content .title {
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: lighter;
}
.title-mobile-icon {
  width: 35px;
  margin-left: 5px;
}
.mobile .main-row .img-div {
  width: 100%;
}
</style>