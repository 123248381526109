<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "SearchBox"
})
</script>

<template>

  <div class="d-flex align-items-center wrapper-search-box justify-content-between">
    <svg xmlns="http://www.w3.org/2000/svg" class="mx-2" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z" stroke="#BABABA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.9304 20.6898C19.4604 22.2898 20.6704 22.4498 21.6004 21.0498C22.4504 19.7698 21.8904 18.7198 20.3504 18.7198C19.2104 18.7098 18.5704 19.5998 18.9304 20.6898Z" stroke="#BABABA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <input placeholder="sarch by NFTS or Artists" type="text" class="search-box-input">
    <button class="search-btn">
      Expelor
    </button>
  </div>

</template>

<style scoped lang="css">

.search-btn{
  border-radius: 24px 8px 24px 8px;
  background: linear-gradient(90deg, #8839C2, #3AD9DA, #23BCBD);
  color: #fff;
  padding: 15px 50px;
  border: none !important;
  font-size: 20px;
  outline: none !important;
}

.wrapper-search-box {
  background: #29103A;
  border-radius: 24px 0px 24px 0px;
  height: max-content;
  padding: 0 0 0 10px;
  position: relative;
}

.search-box-input {
  border: none !important;
  outline: none !important;
  background: transparent;
  padding: 10px 5px;
}

</style>