<script lang="ts">
import {defineComponent} from 'vue'
import {ApiHelper} from "@/api/api-helper";
import {Modal} from 'bootstrap';
import {openModal, sleep} from "@/helper";
import Web3 from "web3";
import {Web3Helper} from "@/blockchain/web3-helper";
import {TokenContractApi} from "@/blockchain/token-contract.api";
import Cookies from "js-cookie";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "ChestBox",
  data()
  {
    return {
      web3:<Web3><unknown>null,
      currentBalance: 0,
      canBuy: false,
      error: '',
      buyLoading: false,
      tokenApi: <TokenContractApi><unknown>null,
      referrer: <string | null>null,
      imgUrl: ApiHelper.getApiUrl(false) + 'images/'
    }
  },
  computed: {
    ...mapGetters('web3', {
      isWeb3Available: 'isWeb3Available',
      isWeb3Injected: 'isWeb3Injected',
      currentAccount: 'getCurrentAccount',
      currentNetworkId: 'getCurrentNetworkId',
      isConnectedToRightNetwork: 'isConnectedToRightNetwork',
    })
  },
  mounted()
  {
    const ref = Cookies.get('referrer');
    if (ref)
      this.referrer = ref;
    this.initiate();

    // @ts-ignore
    this.emitter.on('web3Changed', (data) =>
    {
      this.initiate()
    });
  },
  methods: {
    async checkCanBuy()
    {
      const r = await this.tokenApi.canBuyChest(this.data.chestId);
      console.log('R: ',r)
      if (r==null)
      {
        this.error = 'Error calling smart contract';
        return;
      }
      /*
      if (!itoIsActive)
            return 1;
        if (_chestId > chests.length)
            return 2;
        if (!chests[_chestId - 1].isActive)
            return 3;
        if (chests[_chestId - 1].remaining <= 0)
            return 4;
        return 0;
       */
      if (r==='1')
      {
        this.error = 'ITO is not active right now.';
        return;
      }
      if (r==='2')
      {
        this.error = 'Chest info not found on smart contract.';
        return;
      }
      if (r==='3')
      {
        this.error = 'Chest is not active.';
        return;
      }
      if (r==='4')
      {
        this.error = 'This chest has sold out.';
        return;
      }
      this.canBuy = true;
      this.error = ''
    },
    async initiate(retry = 0)
    {
      await sleep(1200);
      if (this.isWeb3Injected && this.isConnectedToRightNetwork)
      {
        const web3 = new Web3(Web3Helper.getWindowWeb3());
        this.web3 = web3;
        this.tokenApi = new TokenContractApi(web3);
        console.log('REF: ',await this.tokenApi.getReferrer(this.currentAccount))
        // const b = Number(await Web3Helper.getNativeBalance(web3, this.currentAccount));
        // if (!isNaN(b))
        //   this.currentBalance = Math.round(b / 10 ** 14) / 1000;


        this.checkCanBuy();
      }
      else if (retry < 10)
        await this.initiate()
    },
    enableWeb3()
    {
      Web3Helper.enableWeb3()
    },
    async buy()
    {
      //check balance here
      this.error='';

      if (this.isWeb3Injected && this.isConnectedToRightNetwork)
      {
        if (this.data.price > await Web3Helper.getNativeBalance(this.web3, this.currentAccount))
        {
          this.error ='insufficient BNB balance';
          return;
        }
        // const r = await this.tokenApi.buyChest(this.data.chestId,this.referrer?this.referrer:'0x0000000000000000000000000000000000000000',this.data.price);
        console.log(this.referrer)
        const r = await this.tokenApi.buyChest(this.data.chestId,this.referrer?this.referrer:'0xc08f39748A0c25fc32dd2265CCB49C35A4e133f1',this.data.price);
        if (r)
        {
          this.setChestModalInfo();
          this.openModal('buy-chest-modal');
          this.data.sold++;
          this.data.remaining--;
          this.checkCanBuy();
        }
        else
          this.error = 'error in purchasing the chest.';
      }
    },
    setChestModalInfo()
    {
      const img = document.getElementById('purchased-chest-img');
      const title = document.getElementById('purchased-chest-title');
      const tokens = document.getElementById('purchased-chest-tokens');
      if (title)
        title.innerHTML = this.data.title
      if (tokens)
        tokens.innerHTML = String(this.data.tokens / (10 ** 8))
      if (img)
        img.setAttribute('src', this.imgUrl + 'chest-' + this.data.chestId + '-open.webp')
    },
    openModal(id)
    {
      openModal(id)
    },
  },
  props: ['data']
})
</script>

<template>

  <div class="wrapper-chest-box d-flex flex-column">
    <div class="d-flex flex-column chest-wrapper">
      <img :src="imgUrl+data.picName+'-close.webp'" alt="">

      <div class="d-flex flex-column">
        <div class="d-flex flex-column border-y-1 py-3">
          <span class="text my-2">
            {{ data.title }}
          </span>
          <!--          <span class="text my-2">-->
          <!--            {{ data.title }}-->
          <!--          </span>-->
          <div class="d-flex align-items-center justify-content-between my-2">
            <span>
              DG Tokens
            </span>
            <span>
              {{ (data.tokens / (10 ** 8)) }} Tokens
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-between my-2">
            <span>
              Mint monkey
            </span>
            <span>
              {{ data.monkeyPercent }}% posiiblity
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-between my-2">
            <span>
              Remaining
            </span>
            <span>
              {{ data.remaining }} ({{data.sold}} Sold)
            </span>
          </div>
        </div>

        <div class="d-flex align-items-end justify-content-between">
          <div class="d-flex flex-column">
            <span>
              price
            </span>
            <span>
              {{ (data.price / (10 ** 18)) }} BNB
            </span>
          </div>
          <div>
            <button v-if="!isWeb3Available" type="button" class="btn btn-gradient btn-lg"
                    @click="openModal('installMetaMaskModal')">Please install Metamask
            </button>
            <button v-else-if="!isConnectedToRightNetwork" type="button" class="btn btn-gradient btn-lg"
                    @click="openModal('wrongNetworkModal')">
              Wrong Network
            </button>
            <button v-else-if="!isWeb3Injected" type="button" class="btn gold-gradient-btn" @click="enableWeb3">Connect
              Wallet
            </button>
            <button v-else style="border-radius: 15px 0px" class="btn btn-gradient px-4" type="submit"
                    :disabled="!canBuy || buyLoading" @click="buy">{{ buyLoading ? 'Please wait ...' : 'Buy' }}</button>
          </div>
        </div>
        <div class="mt-1 text-danger" v-if="error">{{error}}</div>
      </div>

    </div>
  </div>

</template>

<style scoped lang="css">

.text {
  color: #BABABA;
}

.wrapper-chest-box {
  background: linear-gradient(#9400FF, #3AD9DA, #54F5F6);
  height: 720px;
  width: 400px;
  border-radius: 24px 4px 24px 4px;
  padding: 1px;

  .chest-wrapper {
    background: linear-gradient(#4f4b61, #191e2a);
    height: 100%;
    padding: 15px;
    border-radius: 24px 4px 24px 4px;

    img {
      height: 350px;
    }

  }

  .border-y-1 {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    margin: 25px 0;
  }

}

</style>