import {ApiHelper} from "@/api/api-helper";
import {Axios} from "axios";
import jwtInterceptor from "@/api/jwt-interceptor";
import {ChestInfoType} from "@/api/chest-info.type";

export class Api
{
  private apiUrl:string;
  private interceptor:Axios;

  constructor()
  {
    this.apiUrl = ApiHelper.getApiUrl();
    this.interceptor = jwtInterceptor;
  }

  async getChests():Promise<ChestInfoType[]>
  {
    try
    {
      const r = await this.interceptor.get(this.apiUrl + 'chests/get');
      if (r && r.data.ok)
        return r.data.chests;
      console.log(r.data.error)
      return [];
    }
    catch (e)
    {
      console.log(e)
    }
    return [];
  }
}