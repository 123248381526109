"use strict";

import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import Web3Module from "./modules/web3-store";

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage,
// @ts-ignore
  reducer: state => ({ user: state.user })
});

const store = createStore({
  plugins: [vuexLocalStorage.plugin],
  modules: {
    web3:Web3Module
  },
});

export default store;
