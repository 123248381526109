import Web3 from "web3";
import {Web3Helper} from "@/blockchain/web3-helper";
import * as net from "net";
import {waitForPageLoad} from "@/helper";
import * as process from "process";

const state = () => ({
  pageLoaded: false,
  isWeb3Available: false,
  isWeb3Injected: false,
  networkId: null,
  isConnectedToRightNetwork: false,
  currentAccount: null,
  referrer:<string | null>null,
  platform:<string>'desktop'
});

const mutations = {
  // setReferrer(state, referrer)
  // {
  //   console.log('aallii '+referrer)
  //   state.referrer = referrer;
  // }
  setPlatform(state,platform)
  {
    state.platform = platform
  },
};

const actions = {
  async initiateState({state})
  {
    if (!state.pageLoaded)
    {
      await waitForPageLoad();
      state.pageLoaded = true;
    }
    if (Web3Helper.isWeb3Available())
    {
      state.isWeb3Available = true;
      const networkId = process.env.VUE_APP_NETWORK_ID;
      // const networkId = '5777';
      const currentNetworkId = await Web3Helper.getNetworkId();
      if (currentNetworkId)
      {
        console.log(currentNetworkId)
        state.networkId = currentNetworkId;
        if (currentNetworkId === networkId)
        {
          state.isConnectedToRightNetwork = true;
          state.isWeb3Injected = await Web3Helper.isWeb3Injected();
          if (state.isWeb3Injected)
          {
            const accounts = await Web3Helper.getAccounts();
            if (accounts && accounts.length)
              state.currentAccount = accounts[0];
            else
              state.currentAccount = null;
          }
          else
          {
            state.currentAccount = null;
          }
        }
        else
        {
          state.currentAccount = null;
          state.isConnectedToRightNetwork = false;
        }
      }
      else
      {
        state.currentAccount = null;
        state.isConnectedToRightNetwork = false;
      }
    }
    else
    {
      state.currentAccount = null;
      state.isConnectedToRightNetwork = false;
    }
  },
  setPlatform({commit},platform)
  {
    commit('setPlatform',platform)
  }
  // setReferrer({commit},referrer)
  // {
  //   commit('setReferrer',referrer)
  // }
};

const getters = {
  platform(state)
  {
    return state.platform;
  },
  referrer(state)
  {
    console.log('ggg')
    return state.referrer
  },
  isWeb3Available(state)
  {
    return state.isWeb3Available
  },
  isWeb3Injected(state)
  {
    return state.isWeb3Injected;
  },
  getCurrentAccount(state)
  {
    return state.currentAccount;
  },
  getCurrentNetworkId(state)
  {
    return state.networkId
  },
  isConnectedToRightNetwork(state)
  {
    return state.isConnectedToRightNetwork;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
