<script lang="ts">
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";

export default defineComponent({
  name: "RoadMap",
  mounted() {
  },
  computed:{
    ...mapGetters('web3', {
      platform : "platform"
    })
  }
})
</script>

<template>

  <div class="container" id="roadMpaPart">
    <div v-if="platform === 'mobile'" class="items">
      <img src="@/assets/images/rocket2.webp" alt="" id="rocket_img" class="rocket-img">
      <div class="planet-item planet1 animate__animated">
        <div class="pin first-pin">
          <svg class="dot" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="11" fill="#D9D9D9"/>
            <circle cx="16" cy="16" r="15.5" stroke="#D9D9D9"/>
          </svg>
        </div>
        <div class="img">
          <img src="@/assets/images/planet1.webp">
        </div>
        <div class="content">
          Demo publishment
          ITO launching
          Limited sales with bonus
        </div>
      </div>
      <div class="planet-item planet2 animate__animated">
        <div class="pin">
          <svg class="dot" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="11" fill="#D9D9D9"/>
            <circle cx="16" cy="16" r="15.5" stroke="#D9D9D9"/>
          </svg>
        </div>
        <div class="img">
          <img src="@/assets/images/planet2.webp">
        </div>
        <div class="content">
          Beta version publishment
          Starting Beta token game reward
          Character and Monkey NFTs launching
          Unlocking 7k monkey NFT mint
        </div>
      </div>
      <div class="planet-item planet3 animate__animated">
        <div class="pin">
          <svg class="dot" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="11" fill="#D9D9D9"/>
            <circle cx="16" cy="16" r="15.5" stroke="#D9D9D9"/>
          </svg>
        </div>
        <div class="img">
          <img src="@/assets/images/planet3.webp">
        </div>
        <div class="content">
          Main version publishment
          Listing token on DEX and CEX
          The launch of ingame Marketplace
          Unlocking 4k monkey NFT mint
        </div>
      </div>
      <div class="planet-item planet4 animate__animated">
        <div class="pin last-pin">
          <svg class="dot" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="11" fill="#D9D9D9"/>
            <circle cx="16" cy="16" r="15.5" stroke="#D9D9D9"/>
          </svg>
        </div>
        <div class="img">
          <img src="@/assets/images/planet4.webp">
        </div>
        <div class="content">
          Beta version of Open World Metaverse
          Adding Open world Airdrop events
          Updating whitepaper and roadmap
          Testing Beta version of Open World Characters AI
          Unlocking 4k monkey NFT mint
        </div>
      </div>
    </div>
  </div>
  <img v-if="platform === 'desktop'" src="@/assets/images/RoadMap.webp" alt="" class="road-img">

</template>

<style scoped lang="css">
.road-img
{
  width: 100%;
}
.mobile .road-img
{
  margin: 10% 0;
}
.mobile .container
{
  background-image: url("@/assets/images/roadBack.jpg");
  background-position: center;
  background-size: contain;
  position: relative;
}
.planet-item
{
  display: flex;
  justify-content: space-between;
  grid-gap: 2%;
  flex-wrap: wrap;
  //padding-bottom: 10px;
}
.planet-item .img {
  flex: 0 0 35%;
  padding: 20px;
}
.planet-item .pin {
  flex: 0 0 10%;
  display: flex;
  align-items: center;
}
.img img
{
  width: 100%;
}
.planet-item .content {
  flex: 0 0 46%;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0.05) 100%);
  padding: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}
.pin
{
  position: relative;
}
.pin .dot
{
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 1;
}
.pin::after {
  content: '';
  position: absolute;
  height: 100%;
  border-right: 3px dashed silver;
  bottom: 0;
  left: 47%;
  transform: translateX(-50%);
}
.items .first-pin:after
{
  height: 50%;
}
.items .last-pin:after
{
  top: 0;
  height: 50%;
}
.items
{
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}
.rocket-img {
  position: absolute;
  display: block;
  /* margin: 0 auto; */
  left: 50%;
  transform: translateX(-50%) translateY(10%);
  //bottom: -56%;
  top: 0%;
  z-index: 2;
}

</style>